.enhanceRegistry .backLink {
  position: absolute;
  top: 28px;
  left: 40px;
  font-size: 16px;
}
.enhanceRegistry .title {
  margin-bottom: 40px;
  text-align: center;
}
.enhanceRegistry .tiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
.enhanceRegistry .footer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px 40px;
  border-top: 1px solid #b7b7b7;
}
.enhanceRegistry .doneButton {
  width: 240px;
}
